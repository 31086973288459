import React from 'react'

const splashScreen = () => {
    return (
        <div className='w-full h-[100vh] flex justify-center items-center'>
            <p className='text-3xl logo'> &lt;AS/&gt;</p>
        </div>
    )
}

export default splashScreen