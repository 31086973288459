import css from "../images/tech/css.png";
import git from "../images/tech/git.png";
import html from "../images/tech/html.png";
import javascript from "../images/tech/javascript.png";
import mongodb from "../images/tech/mongodb.png";
import nodejs from "../images/tech/nodejs.png";
import reactjs from "../images/tech/reactjs.png";
import redux from "../images/tech/redux.png";
import tailwind from "../images/tech/tailwind.png";
import typescript from "../images/tech/typescript.png";
import vite from "../images/tech/vite.png";
import graphql from "../images/tech/graphql.png";
import jest from "../images/tech/jest.png";
import Cpp from "../images/tech/Cpp.png";
import C from "../images/tech/C.png";

export const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "git",
    icon: git,
  },
  {
    name: "Vite",
    icon: vite,
  },
  {
    name: "Jest",
    icon: jest,
  },
  {
    name: "GraphQL",
    icon: graphql,
  },
  {
    name: "C++",
    icon: Cpp,
  },
  {
    name: "C",
    icon: C,
  },
];

