export const educationData = [
    {
        title: "MCA",
        subTitle: "Chandigarh University",
        result: "July 2024 - July 2026",
        des: "I am pursuing a Master in Computer Applications from Chandigarh University for further professional growth and to enhance my expertise in the field.",
    },
    {
        title: "BCA",
        subTitle: "GNIMT",
        result: "July 2020 - July 2023",
        des: "Graduated from Guru Nanak Institute of Management and Technology with a Bachelor's degree in Computer Applications, gaining expertise in various aspects of computer applications and technology.",
    },
    {
        title: "12th Non-Medical",
        subTitle: "Green Land Convent School",
        result: "March 2019 - March 2020",
        des: "Completed 12th grade with a Non-Medical stream from Green Land Convent School, providing a strong foundation in science and mathematics.",
        lastChild: true,
    },
];
