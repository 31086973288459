export const achievementData = [
    {
        title: "TECH-DISHA-2023",
        subTitle: "Arya College, Ludhiana",
        result: "1st Position",
        des: "Secured 1st rank in IT Competition (Code Gulf) in Arya College, Ludhiana.",
    },
    {
        title: "SPECTRA-2020",
        subTitle: "GGN, Ludhiana",
        result: "3rd Position",
        des: "Secured 3rd rank in IT Competition (Code Debugging) in GGN, Ludhiana.",
    },
    {
        title: "TECH-DISHA-2022",
        subTitle: "Arya College, Ludhiana",
        result: "1st Position",
        des: "Secured 1st rank in IT Competition (Code Gulf) in Arya College, Ludhiana.",
        lastChild: true,
    },
];
