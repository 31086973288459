import React, { useState } from 'react'
import Title from '../layouts/Title';
import Education from './Education';
import Skills from './Skills';
import Achievement from './Achievement';
import Experience from "./Experience"
import { educationData } from '../../assets/content/EducationDetails';
import { experienceDetails } from '../../assets/content/ExperienceDetails';
import { achievementData } from '../../assets/content/AchievementDetails';

const Resume = () => {
  const [education, setEducationData] = useState(true);
  const [skillData, setSkillData] = useState(false);
  const [experienceData, setExperienceData] = useState(false);
  const [achievement, setAchievementData] = useState(false);
  return (
    <section id="resume" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title title="1+ YEARS OF EXPERIENCE" des="My Resume" />
      </div>
      <div>
        <ul className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
          <li
            onClick={() =>
              setEducationData(true) &
              setSkillData(false) &
              setExperienceData(false) &
              setAchievementData(false)
            }
            className={`${education
              ? "border-designColor rounded-lg"
              : "border-transparent"
              } resumeLi`}
          >
            Education
          </li>
          <li
            onClick={() =>
              setEducationData(false) &
              setSkillData(true) &
              setExperienceData(false) &
              setAchievementData(false)
            }
            className={`${skillData ? "border-designColor rounded-lg" : "border-transparent"
              } resumeLi`}
          >
            Professional Skills
          </li>
          <li
            onClick={() =>
              setEducationData(false) &
              setSkillData(false) &
              setExperienceData(true) &
              setAchievementData(false)
            }
            className={`${experienceData
              ? "border-designColor rounded-lg"
              : "border-transparent"
              } resumeLi`}
          >
            Experience
          </li>
          <li
            onClick={() =>
              setEducationData(false) &
              setSkillData(false) &
              setExperienceData(false) &
              setAchievementData(true)
            }
            className={`${achievement
              ? "border-designColor rounded-lg"
              : "border-transparent"
              } resumeLi`}
          >
            Achievements
          </li>
        </ul>
      </div>
      {education && <Education educationData={educationData} />}
      {skillData && <Skills />}
      {achievement && <Achievement educationData={achievementData} />}
      {experienceData && <Experience educationData={experienceDetails} />}

    </section>
  );
}

export default Resume